<template>
  <v-container class="d-flex flex-column" fluid>
    <v-select
      v-if="$vuetify.breakpoint.mobile"
      dense
      class=""
      :items="projects"
      item-text="name"
      item-value="id"
      @change="changeProject"
      v-model="selectedItem"
      :label="$t('message.Projects')"
    />
    <v-col cols="12" sm="5" class="mx-auto">
      <radar-chart :key="chartUpdate" :chart-data="chartData" :options="chartOptions" />
    </v-col>
    <v-row no-gutters>
      <v-col offset="3" cols="6">
        <v-sheet class="dataTableRow ma-4 pa-4 justify-center" rounded>
          <v-row no-gutters class="align-center justify-center">
            <v-col class="justify-center">
              <h5>White: Current</h5>
            </v-col>
            <v-col>
              <h5>Gray: History</h5>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RadarChart from '@/components/charts/RadarChart.vue';
import apiService from '@/services/apiService.js';
import { mapGetters } from 'vuex';
export default {
  components: {
    RadarChart,
  },
  computed: {
    ...mapGetters(['user', 'langList', 'isAdmin', 'darkMode']),
    chartData() {
      var middle = this.triangleCurrentData ? 0 : null;
      return {
        labels: [this.$t('RiskProfile.Time'), this.$t('RiskProfile.Expenses'), this.$t('RiskProfile.Scope')],
        datasets: [
          // Current : Expense
          {
            data: [middle, this.triangleCurrentData?.expensesStatus, null],
            borderWidth: 10,
            fill: false,
            borderColor: 'white',
            pointRadius: 3,
          },
          // Current : Time
          {
            data: [this.triangleCurrentData?.timeStatus, middle, null],
            borderWidth: 10,
            fill: false,
            backgroundColor: 'rgba(000,000,000,000)',
            borderColor: 'white',
            pointRadius: 3,
          },
          // Current : Scope
          {
            data: [null, middle, this.triangleCurrentData?.scopeStatus],
            borderWidth: 10,
            fill: false,
            backgroundColor: 'rgba(000,000,000,000)',
            borderColor: 'white',
            pointRadius: 3,
          },
          // History : Expense
          {
            data: [null, 3],
            borderWidth: 10,
            fill: false,
            borderColor: '	rgba(90, 90, 90, 0)',
            pointBorderColor: 'rgba(90, 90, 90, 0.8)',
            pointRadius: 15,
            pointStyle: 'line',
            rotation: -60,
          },
          // History : Time
          {
            data: [1, null],
            borderWidth: 10,
            fill: false,
            borderColor: '	rgba(90, 90, 90, 0)',
            pointBorderColor: 'rgba(90, 90, 90, 0.8)',
            pointRadius: 15,
            pointStyle: 'line',
          },
          // History : Scope
          {
            data: [null, null, 2],
            borderWidth: 10,
            fill: false,
            borderColor: 'rgba(90, 90, 90, 0)',
            pointBorderColor: 'rgba(90, 90, 90, 0.8)',
            pointRadius: 15,
            pointStyle: 'line',
            rotation: 60,
          },

          // Background

          {
            data: [1.5, 1.5, 1.5],
            fill: true,
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            pointBackgroundColor: 'rgba(000,000,000,000)',
            pointBorderColor: 'rgba(000,000,000,000)',
            pointHoverBackgroundColor: 'rgba(000,000,000,000)',
            pointHoverBorderColor: 'rgba(000,000,000,000)',
          },
          {
            data: [2.5, 2.5, 2.5],
            fill: true,
            backgroundColor: '#fb8c00',
            borderColor: '#fb8c00',
            pointBackgroundColor: 'rgba(000,000,000,000)',
            pointBorderColor: 'rgba(000,000,000,000)',
            pointHoverBackgroundColor: 'rgba(000,000,000,000)',
            pointHoverBorderColor: 'rgba(000,000,000,000)',
          },
          {
            data: [3.5, 3.5, 3.5],
            fill: true,
            backgroundColor: '#ff5252',
            borderColor: '#ff5252',
            pointBackgroundColor: 'rgba(000,000,000,000)',
            pointBorderColor: 'rgba(000,000,000,000)',
            pointHoverBackgroundColor: 'rgba(000,000,000,000)',
            pointHoverBorderColor: 'rgba(000,000,000,000)',
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        legend: false,
        tooltips: false,
        title: false,
        display: false,
        hover: {
          mode: null,
        },
        layout: {
          padding: 25,
        },
        scale: {
          pointLabels: {
            fontSize: 15,
            fontColor: this.$vuetify.theme.dark ? 'white' : 'black',
          },
          angleLines: {
            display: false,
          },
          ticks: {
            display: false,
            stepsize: 1,
            suggestedMin: 0,
            suggestedMax: 3,
          },
        },
      };
    },
  },
  data: () => ({
    triangleCurrentData: {},
    currentProjectId: 0,
    chartUpdate: 0,
    selectedItem: 0,
    projects: [],
    project: {},
    riskList: [],
  }),
  methods: {
    async getProjects() {
      if (this.user) {
        this.selectedItem = this.currentProjectId;
        this.projects = await apiService.getProjects();
        if (!this.selectedItem) {
          this.selectedItem = (await this.projects) ? this.projects[0].id : null;
          this.$store.dispatch('SET_PROJECT_ID', this.selectedItem);
        }
      }
    },
    async getProjectById(projectId) {
      this.project = await apiService.getProjectById(projectId);
      this.riskList = await apiService.getProjectRisks(this.currentProjectId);
      await this.getTriangleData();
    },
    async getTriangleData() {
      this.triangleCurrentData = await this.$util.calculateTriangleStatus(this.riskList);
    },
    changeProject() {
      this.$store.dispatch('SET_PROJECT_ID', this.selectedItem);
    },
  },
  created() {
    if (!this.currentProjectId && this.$route.params.projectId) {
      this.currentProjectId = this.$route.params.projectId;
    }
    this.getProjectById(this.currentProjectId);
    if (this.$vuetify.breakpoint.mobile) {
      this.getProjects();
    }
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
