<script>
import { Radar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  watch: {
    options() {
      this.$data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>